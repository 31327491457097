<template>
  <div>
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <SectionHeading classname="text-center" text="Our Customers Say" content="We have many happy investors invest with us.
              Some impresions from our Customers!
              Please read some of the lovely things our Customers
              say about us."  isMargin="mb-3 mt-5"></SectionHeading>

    <!-- 顶部 -->


    <!--Shape End-->
    <section class="section pt-0">
      <div class="container">
        <div class="p-3 border-top" v-for="i in dataList" :key="i">
          <div style="display: flex;flex-flow: row nowrap;justify-content: space-between;">
            <span style="font-weight: bold;">{{i.email}}</span>
            <el-rate v-model="i.ratings" disabled></el-rate>
          </div>
          <div style="color: gray;font-size: 14px;"><i class="el-icon-time"></i>{{parseTime(i.createdAt, '{d}/{m}/{y}') }}</div>
          <div class="p" style="font-weight: bold;">{{i.title}}</div>
          <div class="p" v-html="i.content">

          </div>
        </div>
      </div>
      <div class="container">
        <div
            style="border: 1px #efefef solid;border-radius: 10px;width: 220px;padding: 15px 15px;margin-top: 10px;margin: 10px auto;">
					<span @click="Preview"
                style="padding: 5px 10px;background-color: #f9f9f9;color: black;border-radius: 8px;font-weight: bold;cursor: pointer;user-select: none;">
						<i class="el-icon-back" style="margin-right: 3px;"></i>Previous
					</span>
          <span @click="Next"
                style="padding: 5px 10px;background-color: #f9f9f9;color: black;border-radius: 8px;margin-left: 5px;font-weight: bold;cursor: pointer;user-select: none;">
						Next<i class="el-icon-right" style="margin-left: 3px;"></i>
					</span>
        </div>
      </div>
      <div class="container p-3">
        <label style="font-weight: bold;font-size: 18px;">Leave A Review</label>
        <div>
          <label style="font-weight: bold;font-size: 14px;">Email</label>
          <el-input v-model="saveModel.email"></el-input>
          <label style="font-weight: bold;font-size: 14px;">Source</label>
          <el-rate v-model="saveModel.ratings"></el-rate>
          <label style="font-weight: bold;font-size: 14px;">Title</label>
          <el-input v-model="saveModel.title"></el-input>
          <label style="font-weight: bold;font-size: 14px;">Review</label>
          <el-input type="textarea" v-model="saveModel.content" :rows="4">
          </el-input>
        </div>
        <el-button style="border-radius: 10px;margin-top: 20px;" @click="saveComment" type="primary">SUBMIT</el-button>
      </div>
    </section>


    <Footer classname="bg-black on-dark"></Footer>

    <!-- Footer End -->
    <!--		<Switcher />-->
    <!-- Back to top -->
<!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
<!--       v-scroll-to="'#topnav'">-->
<!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
<!--    </a>-->
    <!-- Back to top -->
  </div>
</template>

<script>

	import indexApi from "@/apis/indexApi"
  import {parseTime} from "@/date";

// import {ElMessage} from "element-plus";


	/**
	 * Index-1
	 */
	export default {
    metaInfo: {
      title: 'NFT INVESTMENT PLATFORM',
      link: [
        { rel: 'canonical', href: 'https://***.com/reviews' }
      ]
    },
		data() {
			return {
				data: {
					title: "",
					longDesc: ""
				},
				login: false,
				load: false,
				value1: 5,
				selectModel: {
					pageNum:1,
					pageSize:5
				},
				saveModel: {
					content: "",
					createdAt: "",
					email: "",
					// id: 0,
					ratings: 0,
					title: "",
					type: 1
				},
				dataList:[],
				limt:1
			}
		},

		components: {

		},
		created() {
			this.selectList()
		},
		methods: {
      parseTime,
			selectList(){
				indexApi.GetComments(
					this.selectModel,
					res=>{
						this.dataList.length=0
						this.dataList=res.data.result
						this.dataList.forEach(i=>{
							i.createdAt=i.createdAt.split('T')[0].replace('-','/')
						})
						this.limt=res.data.totalPages
					}
				)
			},
			saveComment(){
				if(this.saveModel.content==""||this.saveModel.email==""||this.saveModel.title==""){
          this.$message({
						message:"Please enter to fill in all data",
						type:"error"
					})
					return
				}
				if(this.saveModel.email.indexOf('@')==-1){
          this.$message({
						message:"Please enter the correct email address",
						type:"error"
					})
					return
				}
				this.saveModel.createdAt = new Date().toISOString()
				var savedata = JSON.parse(JSON.stringify(this.saveModel))
				savedata.content = "<p>"+this.saveModel.content+"</p>"
				indexApi.SetComment({
					data:savedata
				}, res=>{
          console.log(res)
          this.$message({
            message:"success",
            type:"success"
          })
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
			},
			Preview(){
				if(this.selectModel.pageNum==1){
          this.$message({
						message:"It's the top",
						type:"error"
					})
					return
				}
				this.selectModel.pageNum--
				this.selectList()
			},
			Next(){
				if(this.selectModel.pageNum==this.limt){
          this.$message({
						message:"No more can be found",
						type:"error"
					})
					return
				}
				this.selectModel.pageNum++
				this.selectList()
			}
		}
	};
</script>



<style scoped>
	.p {
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-all;
	}
</style>
